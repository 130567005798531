import React from "react"
import styled from "styled-components"

import { graphql } from "gatsby"

import {
  Layout,
  Seo,
  Link,
  TextGroup,
  Button,
  HContainer,
} from "components/common"
import { useMeta, useRichText, richTextBody, device } from "utils"

import Nav from "components/navigation/Nav"
import MobileNav from "src/components/navigation/mobile/MobileNav"

import ArrowBack from "images/common/arrow-back.inline.svg"
import Footer from "src/components/navigation/Footer"

const Career = ({ data: { career } }) => {
  const {
    position,
    jobDescription,
    linkedinUrl,
    metaDescription,
    metaImage,
    // positionIcon,
  } = career

  const meta = useMeta({
    metaTitle: `${position}`,
    metaDescription,
    metaImage,
  })

  const renderDescription = useRichText({ text: jobDescription })

  return (
    <Layout>
      <Seo {...meta} />

      <Nav />
      <MobileNav />

      <StyledCareer>
        <HContainer>
          <CarreerContent>
            <Link className="back" to="/careers">
              <ArrowBack />
              Back to all job postings
            </Link>
            <div className="descriptionWrap">
              <TextGroup className="position" title={position} titleAs="h1" />

              <div className="description">{renderDescription}</div>
              <div className="ctaWrap">
                {linkedinUrl && (
                  <a
                    href={linkedinUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="linkedin"
                  >
                    <Button>Apply via Linkedin</Button>
                  </a>
                )}
                <a
                  href="mailto:hr@deepwaters.xyz"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button>Apply via Email</Button>
                </a>
              </div>
            </div>
          </CarreerContent>
        </HContainer>
      </StyledCareer>
      <Footer />
    </Layout>
  )
}

const StyledCareer = styled.div`
  --section-max-width: 1080px;

  position: relative;
  z-index: 1;
  min-height: calc(var(--vh, 1vh) * 100);

  padding-top: var(--sp-mobile-nav);
  padding-bottom: var(--sp-section-mobile);

  @media ${device.laptop} {
    padding-top: var(--sp-nav);
    padding-bottom: var(--sp-section);
  }
`

const CarreerContent = styled.section`
  .back {
    display: inline-flex;
    align-items: center;
    font-size: 1.125rem;
    color: white;
    font-weight: 500;
    svg {
      margin-right: var(--sp-8);
    }
  }
  .descriptionWrap {
    margin-top: var(--sp-24);

    .position {
      margin-bottom: var(--sp-32);
    }
    .description {
      ${richTextBody};
    }
  }

  .ctaWrap {
    display: flex;
    margin-top: var(--sp-64);
    flex-direction: column;
    align-items: center;

    @media ${device.laptop} {
      justify-content: center;
      flex-direction: row;
    }
    .linkedin {
      margin-bottom: 16px;
      @media ${device.laptop} {
        margin-bottom: 0px;

        margin-right: 24px;
      }
    }
  }
`

export default Career

export const query = graphql`
  query CareerSlug($slug: String!) {
    career: contentfulCareer(slug: { eq: $slug }) {
      ...Career
    }
    # cta_get_demo: contentfulSection(sectionId: { eq: "cta_get_demo" }) {
    #   ...Section
    # }
  }
`
